import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {useEffect} from 'react'

const firebaseConfig = {
  apiKey: "AIzaSyC-hDHdeShurPXGLgIqfwXXJJGxrKjcaGA",
  authDomain: "option51-9b422.firebaseapp.com",
  projectId: "option51-9b422",
  storageBucket: "option51-9b422.appspot.com",
  messagingSenderId: "260773832270",
  appId: "1:260773832270:web:b21e54afe57e646adc851f",
  measurementId: "G-9NZYZDNW68"
};

function App() {
  useEffect(() => {
    const firebaseApp = initializeApp(firebaseConfig);
    const analytics = getAnalytics(firebaseApp);
    logEvent(analytics, 'page_view')
  }, [])
  
  return (
    <div className="App">
      <div className="comingSoon">
        Coming Soon
      </div>
    </div>
  );
}

export default App;
